let access_token = "";
let user = null;

if (localStorage.getItem("db")) {
  const dirtyStorage = JSON.parse(localStorage.getItem("db"));
  access_token = dirtyStorage.access_token;
  user = dirtyStorage.user;
}

const initialState = {
  access_token,
  user,
};

export const generalAuthRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case "AUTH_REQUEST_GET":
      return state;
    case "AUTH_REQUEST_SET":
      return {
        ...state,
        authRequest: action.authRequest,
      };
    default:
      return state;
  }
};
export const generalAuthReducer = (
  state = {
    auth: initialState,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_GET":
      return state;
    case "AUTH_SET":
      return {
        ...state,
        auth: action.auth,
      };
    default:
      return state;
  }
};
export const generalBottomReducer = (state = false, action) => {
  switch (action.type) {
    case "BOTTOM_SET":
      return action.bottom;
    default:
      return state;
  }
};
export const generalLoadMoreReducer = (state = false, action) => {
  switch (action.type) {
    case "LOAD_MORE_SET":
      return action.more;
    default:
      return state;
  }
};
export const generalDraftReducer = (state = [], action) => {
  switch (action.type) {
    case "DRAFT_SET":
      return action.draft;
    default:
      return state;
  }
};
export const generalLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case "LOADING_SET":
      return action.loading;
    default:
      return state;
  }
};

export const generalAlertReducer = (state = false, action) => {
  switch (action.type) {
    case "ALERT_SET":
      return action.alert;
    default:
      return state;
  }
};

export const generalChannelReducer = (state = [], action) => {
  switch (action.type) {
    case "CHANNEL_SET":
      return action.channel;
    default:
      return state;
  }
};
