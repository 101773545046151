export const interactionReducer = (
  state = { count: { handling: 0, queue: 0, resolve: 0 }, data: [] },
  action
) => {
  switch (action.type) {
    case "INTERACTION_GET":
      return state;
    case "INTERACTION_SET":
      return {
        ...state,
        ...action.interaction,
      };
    default:
      return state;
  }
};
export const messageReducer = (state = [], action) => {
  switch (action.type) {
    case "MESSAGE_GET":
      return state;
    case "MESSAGE_SET":
      return action.message;
    default:
      return state;
  }
};
export const notificationReducer = (state = [], action) => {
  switch (action.type) {
    case "NOTIFICATION_GET":
      return state;
    case "NOTIFICATION_SET":
      return action.notification;
    default:
      return state;
  }
};
export const currentReducer = (state = null, action) => {
  switch (action.type) {
    case "CURRENT_GET":
      return state;
    case "CURRENT_SET":
      return action.current;
    default:
      return state;
  }
};
export const qrReduer = (state = null, action) => {
  switch (action.type) {
    case "QR_GET":
      return state;
    case "QR_SET":
      return action.qr;
    default:
      return state;
  }
};
