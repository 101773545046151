import * as actions from "../store/actions";
// eslint-disable-next-line
export default {
  mapStateToProps(state) {
    return {
      auth: state.auth.auth,
      interaction: state.interaction,
      notification: state.notification,
      message: state.message,
      current: state.current,
      bottom: state.bottom,
      more: state.more,
      draft: state.draft,
      loading: state.loading,
      alert: state.alert,
      qr: state.qr,
      customer: state.customer,
      customerCurrent: state.customerCurrent,
      channel: state.channel,
    };
  },
  mapDispatchToProps(dispatch) {
    return {
      authRequest: (data) => dispatch(actions.generalGetAuthRequest(data)),
      getInteraction: (data) => dispatch(actions.getInteraction(data)),
      setInteraction: (data) => dispatch(actions.setInteraction(data)),
      setCurrent: (data) => dispatch(actions.setCurrent(data)),
      getMessage: (data) => dispatch(actions.getMessage(data)),
      setBottom: (data) => dispatch(actions.generalSetBottom(data)),
      setLoadMore: (data) => dispatch(actions.generalSetLoadMore(data)),
      setLoading: (data) => dispatch(actions.generalSetLoading(data)),
      setDraft: (data) => dispatch(actions.generalSetDraft(data)),
      getChannel: (data) => dispatch(actions.generalGetChannel(data)),
      setNotification: (data) => dispatch(actions.setNotification(data)),
      getCustomer: (data) => dispatch(actions.customerGet(data)),
      setCustomerCurrent: (data) => dispatch(actions.customerCurrentSet(data)),
    };
  },
  isAuthenticated() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(localStorage.getItem("db"));
      if (dirtyStorage.access_token) {
        return dirtyStorage;
      }
    }
    return false;
  },
  roleAdmin() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(localStorage.getItem("db"));
      if (dirtyStorage.access_token) {
        if (dirtyStorage.user?.role === "Admin") {
          return true;
        }
      }
    }
    return false;
  },
  roleAgent() {
    if (localStorage.getItem("db")) {
      const dirtyStorage = JSON.parse(localStorage.getItem("db"));
      if (dirtyStorage.access_token) {
        if (dirtyStorage.user?.role === "Agent") {
          return true;
        }
      }
    }
    return false;
  },
  truncString(str, max, add) {
    add = add || "...";
    return typeof str === "string" && str.length > max
      ? str.substring(0, max) + add
      : str;
  },
  formatDate(date) {
    var d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  randString() {
    var length = 10;
    let result = "";
    let characters = "abdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  formatRupiah(angka, prefix) {
    var number_string = angka
        ? angka
            .toString()
            .replace(/[^,\d]/g, "")
            .toString()
        : "",
      split = number_string.split(","),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      let separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
  },
  pushNotification(message) {
    Notification.requestPermission();
    if (!Notification) {
      alert(
        "Desktop notifications not available in your browser. Try Chromium."
      );
      return;
    }

    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    } else {
      const notification = new Notification("Notifikasi Vabuss", {
        body: message,
      });
      notification.onclick = function () {
        // history.push(`/${route}`);
        notification.close();
      };
    }
  },
  getProvince() {},
  getCity() {},
};
