import { eventChannel } from "redux-saga";
import { put, call, take } from "redux-saga/effects";
import * as actions from "../actions";
import http from "../../utils/http";
import socket from "../../utils/socket";
export function* authWatcher(action) {
  try {
    if (action.data.type === "LOGIN") {
      const data = yield call(http.post, "/v1/auth/login", action.data);
      localStorage.removeItem("db");
      localStorage.setItem(
        "db",
        JSON.stringify({
          access_token: data?.data?.serve?.access_token,
          user: data?.data?.serve?.user,
        })
      );
      yield put(actions.generalSetAuth(data?.data?.serve));
      window.location.reload(true);
    } else {
      yield call(http.get, "/v1/logout");
      localStorage.removeItem("db");
      yield put(actions.generalSetAuth({ access_token: null, user: null }));
      window.location.reload(true);
    }
  } catch (err) {
    console.log(err);
    if (localStorage.getItem("db")) {
      localStorage.removeItem("db");
      window.location.reload(true);
    }
  }
}

export function* channelWatcher(action) {
  try {
    const { data } = yield call(
      http.post,
      "/v1/account/channel",
      action.channel
    );
    yield put(actions.generalSetChannel(data.serve));
  } catch (err) {
    console.log(err);
  }
}

// eslint-disable-next-line
function* triggerChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      window.location.href = "/" + data?.channel;
    };
    socket.triggerChannel(socketEvent);
    return () => {};
  });
}

export function* triggerChannelWatcher() {
  try {
    const trigger = yield call(triggerChannel);
    while (true) {
      const action = yield take(trigger);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}
