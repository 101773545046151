import openSocket from "socket.io-client";
let params = null;
let user_id = null;
if (localStorage.getItem("db")) {
  const storage = JSON.parse(localStorage.getItem("db"));
  params =
    storage.user.role === "Agent"
      ? storage.user.id?.toString()
      : storage.user.tenants.id?.toString();
  user_id = storage.user.id;
}

let socket = params
  ? openSocket(process.env.REACT_APP_SOCKET_URL, {
      query: "id=" + params?.toString(),
      transports: ["websocket"],
    })
  : null;
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  interaction(cb) {
    if (!socket) return;
    socket.on("interaction", (data) => cb(data));
  },
  interactionMessage(cb) {
    if (!socket) return;
    socket.on("interaction_message", (data) => cb(data));
  },
  loading(cb) {
    if (!socket) return;
    socket.on("loading", (data) => cb(data));
  },
  message(cb) {
    if (!socket) return;
    socket.on("message", (data) => cb(data));
  },
  registerTelegran(data) {
    if (!socket) return;
    socket.emit("registerTelegram", data);
  },
  stopTelegram(data) {
    if (!socket) return;
    socket.emit("stopTelegram", data);
  },
  registerWhatsapp(data) {
    if (!socket) return;
    socket.emit("registerWhatsapp", data);
  },
  stopWhatsapp(data) {
    if (!socket) return;
    socket.emit("stopWhatsapp", data);
  },
  registerInstagram(data) {
    if (!socket) return;
    socket.emit("registerInstagram", data);
  },
  stopInstagram(data) {
    if (!socket) return;
    socket.emit("stopInstagram", data);
  },
  qrwhatsapp(cb) {
    if (!socket) return;
    socket.on("qr_whatsapp", (data) => cb(data));
  },
  readywhatsapp(cb) {
    if (!socket) return;
    socket.on("ready_whatsapp", (data) => cb(data));
  },
  triggerChannel(cb) {
    if (!socket) return;
    socket.on("trigger_channel", (data) => cb(data));
  },
  promotion(data) {
    if (!socket) return;
    socket.emit("promotion", data);
  },
  getFeedInstagram() {
    if (!socket) return;
    socket.emit("getFeedInstagram");
  },
  endInteractionWhatsapp(data) {
    if (!socket) return;
    socket.emit("endInteractionWhatsapp", data);
  },
  endInteractionTelegram(data) {
    if (!socket) return;
    socket.emit("endInteractionTelegram", data);
  },
  endInteractionInstagram(data) {
    if (!socket) return;
    socket.emit("endInteractionInstagram", data);
  },
  submitKipWhatsapp(data) {
    if (!socket) return;
    socket.emit("submitKipWhatsapp", data);
  },
  submitKipTelegram(data) {
    if (!socket) return;
    socket.emit("submitKipTelegram", data);
  },
  submitKipInstagram(data) {
    if (!socket) return;
    socket.emit("submitKipInstagram", data);
  },
  sendMessageTelegram(data) {
    if (!socket) return;
    socket.emit("sendMessageTelegram", data);
  },

  sendFileTelegram(data) {
    if (!socket) return;
    socket.emit("sendFileTelegram", data);
  },
  sendMessageInstagram(data) {
    if (!socket) return;
    socket.emit("sendMessageInstagram", data);
  },

  sendFileInstagram(data) {
    if (!socket) return;
    socket.emit("sendFileInstagram", data);
  },
  sendMessageWhatsapp(data) {
    if (!socket) return;
    socket.emit("sendMessageWhatsapp", data);
  },
  sendBlastWhatsapp(data) {
    if (!socket) return;
    data.user_id = user_id;
    socket.emit("sendBlastWhatsapp", data);
  },

  sendBlastTelegram(data) {
    if (!socket) return;
    data.user_id = user_id;
    socket.emit("sendBlastTelegram", data);
  },

  sendFileWhatsapp(data) {
    if (!socket) return;
    socket.emit("sendFileWhatsapp", data);
  },
};
