import React from "react";
import notification from "antd/es/notification";
import Spin from "antd/es/spin";
import Router from "./router";
import http from "../utils/http";
import { connect } from "react-redux";
import helper from "../utils/helper";
import history from "../utils/history";

function Main(props) {
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  React.useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setOpenBackdrop(true);
        return config;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? `${error.response.data.message}`
              : "Terjadi kesalahan pada server, silahkan coba kembali nanti.",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        if (response?.data?.message) {
          notification.success({
            message: response.data.message,
            placement: "bottomRight",
            duration: 5,
          });
        }
        setOpenBackdrop(false);
        return response;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? `${error.response.data.message}`
              : "Terjadi kesalahan pada server, silahkan coba kembali nanti.",
            placement: "bottomRight",
            duration: 5,
          });
          if (error.response !== undefined) {
            if (error.response.status === 301) {
              history.push("/setting");
            }

            if (error.response.status === 302) {
              history.push("/subscribe");
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }, []);
  React.useEffect(() => {
    if (props.alert) {
      notification[props.alert.type]({
        message: props.alert.text,
        placement: "bottomRight",
        duration: 5,
      });
    }
  }, [props.alert]);
  return (
    <>
      <Spin
        spinning={props.loading || openBackdrop}
        tip="Memproses data, mohon tunggu.."
        className="h-full"
      >
        <Router />
      </Spin>
    </>
  );
}

export default connect(helper.mapStateToProps, helper.mapDispatchToProps)(Main);
