import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

/**
 * Auth Pages
 */
const LoginPage = lazy(() => import("../pages/Auth/LoginPage"));
const RegisterPage = lazy(() => import("../pages/Auth/RegisterPage"));
const ForgotPage = lazy(() => import("../pages/Auth/ForgotPage"));
const ResetPage = lazy(() => import("../pages/Auth/ResetPage"));
const SettingsPage = lazy(() => import("../pages/Auth/SettingsPage"));

const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const SubscribePage = lazy(() => import("../pages/SubscribePage"));
const SettlementPage = lazy(() => import("../pages/SettlementPage"));

const AgentPage = lazy(() => import("../pages/Manage/AgentPage"));
const CaseCategoryPage = lazy(() => import("../pages/Manage/CaseCategoryPage"));
const CaseSubCategoryPage = lazy(() =>
  import("../pages/Manage/CaseSubCategoryPage")
);
const ProductPage = lazy(() => import("../pages/Manage/ProductPage"));
const ResponsePage = lazy(() => import("../pages/Manage/ResponsePage"));
const CustomerPage = lazy(() => import("../pages/Manage/CustomerPage"));
const InteractionPage = lazy(() => import("../pages/Manage/InteractionPage"));
const TransactionPage = lazy(() => import("../pages/Manage/TransactionPage"));
const SummaryPage = lazy(() => import("../pages/Manage/SummaryPage"));

const TelegramPage = lazy(() => import("../pages/Channel/TelegramPage"));
const TelegramAddPage = lazy(() => import("../pages/Channel/TelegramAddPage"));
const TelegramBotPage = lazy(() => import("../pages/Channel/TelegramBotPage"));
const TelegramBotAddPage = lazy(() =>
  import("../pages/Channel/TelegramBotAddPage")
);
const TelegramBotEditPage = lazy(() =>
  import("../pages/Channel/TelegramBotEditPage")
);
const TelegramCampaignPage = lazy(() =>
  import("../pages/Channel/TelegramCampaignPage")
);
const TelegramCampaignAddPage = lazy(() =>
  import("../pages/Channel/TelegramCampaignAddPage")
);
const TelegramCampaignEditPage = lazy(() =>
  import("../pages/Channel/TelegramCampaignEditPage")
);

const InstagramPage = lazy(() => import("../pages/Channel/InstagramPage"));
const InstagramAddPage = lazy(() =>
  import("../pages/Channel/InstagramAddPage")
);
const InstagramBotPage = lazy(() =>
  import("../pages/Channel/InstagramBotPage")
);
const InstagramBotAddPage = lazy(() =>
  import("../pages/Channel/InstagramBotAddPage")
);
const InstagramBotEditPage = lazy(() =>
  import("../pages/Channel/InstagramBotEditPage")
);

const InstagramPostPage = lazy(() =>
  import("../pages/Channel/InstagramPostPage")
);
const InstagramPostAddPage = lazy(() =>
  import("../pages/Channel/InstagramPostAddPage")
);
const InstagramPostEditPage = lazy(() =>
  import("../pages/Channel/InstagramPostEditPage")
);

const WhatsappPage = lazy(() => import("../pages/Channel/WhatsappPage"));
const WhatsappAddPage = lazy(() => import("../pages/Channel/WhatsappAddPage"));
const WhatsappBotPage = lazy(() => import("../pages/Channel/WhatsappBotPage"));
const WhatsappBotAddPage = lazy(() =>
  import("../pages/Channel/WhatsappBotAddPage")
);
const WhatsappBotEditPage = lazy(() =>
  import("../pages/Channel/WhatsappBotEditPage")
);
const WhatsappCampaignPage = lazy(() =>
  import("../pages/Channel/WhatsappCampaignPage")
);
const WhatsappCampaignAddPage = lazy(() =>
  import("../pages/Channel/WhatsappCampaignAddPage")
);
const WhatsappCampaignEditPage = lazy(() =>
  import("../pages/Channel/WhatsappCampaignEditPage")
);

const BlankNotAccess = lazy(() => import("../components/Blank/BlankNotAccess"));

const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Router(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/no-access" render={() => <BlankNotAccess />} />
        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute path="/register">
          <RegisterPage />
        </PublicRoute>
        <PublicRoute path="/forgot">
          <ForgotPage />
        </PublicRoute>
        <Route
          exact
          path="/reset/:token?"
          render={(props) =>
            helper.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            ) : props.match.params.token ? (
              <ResetPage token={props.match.params.token} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <PrivateRoute path="/setting">
          <SettingsPage />
        </PrivateRoute>

        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>

        <PrivateRoute path="/profile">
          <ProfilePage />
        </PrivateRoute>

        <PrivateRoute path="/subscribe">
          <SubscribePage {...props} />
        </PrivateRoute>

        <PrivateRoute path="/order">
          <SettlementPage {...props} />
        </PrivateRoute>

        <PrivateRoute path="/agent">
          {helper.roleAdmin() ? <AgentPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/casecategory">
          {helper.roleAdmin() ? <CaseCategoryPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/casesubcategory">
          {helper.roleAdmin() ? <CaseSubCategoryPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/product">
          {helper.roleAdmin() ? <ProductPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/response">
          {helper.roleAdmin() ? <ResponsePage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/customer">
          <CustomerPage />
        </PrivateRoute>

        <PrivateRoute path="/interaction">
          {helper.roleAgent() ? <InteractionPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/transaction">
          <TransactionPage />
        </PrivateRoute>

        <PrivateRoute path="/summary">
          <SummaryPage />
        </PrivateRoute>

        <PrivateRoute path="/whatsapp">
          {helper.roleAdmin() ? <WhatsappPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addwhatsapp">
          {helper.roleAdmin() ? <WhatsappAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/botwhatsapp">
          {helper.roleAdmin() ? <WhatsappBotPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addbotwhatsapp">
          {helper.roleAdmin() ? <WhatsappBotAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/campaignwhatsapp">
          {helper.roleAdmin() ? <WhatsappCampaignPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addcampaignwhatsapp">
          {helper.roleAdmin() ? (
            <WhatsappCampaignAddPage />
          ) : (
            <BlankNotAccess />
          )}
        </PrivateRoute>

        <Route
          exact
          path="/editbotwhatsapp/:id?"
          render={(props) =>
            helper.isAuthenticated() ? (
              helper.roleAdmin() ? (
                <WhatsappBotEditPage {...props} />
              ) : (
                <BlankNotAccess />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />

        <Route
          exact
          path="/editcampaignwhatsapp/:id?"
          render={(props) =>
            helper.isAuthenticated() ? (
              helper.roleAdmin() ? (
                <WhatsappCampaignEditPage {...props} />
              ) : (
                <BlankNotAccess />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />

        <PrivateRoute path="/telegram">
          {helper.roleAdmin() ? <TelegramPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addtelegram">
          {helper.roleAdmin() ? <TelegramAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/bottelegram">
          {helper.roleAdmin() ? <TelegramBotPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addbottelegram">
          {helper.roleAdmin() ? <TelegramBotAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <Route
          exact
          path="/editbottelegram/:id?"
          render={(props) =>
            helper.isAuthenticated() ? (
              helper.roleAdmin() ? (
                <TelegramBotEditPage {...props} />
              ) : (
                <BlankNotAccess />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />

        <PrivateRoute path="/campaigntelegram">
          {helper.roleAdmin() ? <TelegramCampaignPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addcampaigntelegram">
          {helper.roleAdmin() ? (
            <TelegramCampaignAddPage />
          ) : (
            <BlankNotAccess />
          )}
        </PrivateRoute>
        <Route
          exact
          path="/editcampaigntelegram/:id?"
          render={(props) =>
            helper.isAuthenticated() ? (
              helper.roleAdmin() ? (
                <TelegramCampaignEditPage {...props} />
              ) : (
                <BlankNotAccess />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <PrivateRoute path="/instagram">
          {helper.roleAdmin() ? <InstagramPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addinstagram">
          {helper.roleAdmin() ? <InstagramAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/botinstagram">
          {helper.roleAdmin() ? <InstagramBotPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addbotinstagram">
          {helper.roleAdmin() ? <InstagramBotAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <Route
          exact
          path="/editbotinstagram/:id?"
          render={(props) =>
            helper.isAuthenticated() ? (
              helper.roleAdmin() ? (
                <InstagramBotEditPage {...props} />
              ) : (
                <BlankNotAccess />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />

        <PrivateRoute path="/postinstagram">
          {helper.roleAdmin() ? <InstagramPostPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <PrivateRoute path="/addpostinstagram">
          {helper.roleAdmin() ? <InstagramPostAddPage /> : <BlankNotAccess />}
        </PrivateRoute>

        <Route
          exact
          path="/editpostinstagram/:id?"
          render={(props) =>
            helper.isAuthenticated() ? (
              helper.roleAdmin() ? (
                <InstagramPostEditPage {...props} />
              ) : (
                <BlankNotAccess />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);
