export const customerGet = (data) => ({ type: "CUSTOMER_GET", customer: data });

export const customerSet = (data) => ({
  type: "CUSTOMER_SET",
  customer: data,
});

export const customerCurrentGet = (data) => ({
  type: "CUSTOMER_CURRENT_GET",
  current: data,
});

export const customerCurrentSet = (data) => ({
  type: "CUSTOMER_CURRENT_SET",
  current: data,
});
