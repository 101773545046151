export const generalGetAuth = (data) => ({ type: "AUTH_GET", data });

export const generalSetAuth = (auth) => ({
  type: "AUTH_SET",
  auth,
});

export const generalGetAuthRequest = (data) => ({
  type: "AUTH_REQUEST_GET",
  data,
});

export const generalSetARequest = (auth) => ({
  type: "AUTH_REQUEST_SET",
  auth,
});

export const generalSetBottom = (bottom) => ({
  type: "BOTTOM_SET",
  bottom,
});

export const generalSetLoadMore = (more) => ({
  type: "LOAD_MORE_SET",
  more,
});

export const generalSetDraft = (draft) => ({
  type: "DRAFT_SET",
  draft,
});

export const generalSetLoading = (loading) => ({
  type: "LOADING_SET",
  loading,
});

export const generalSetAlert = (alert) => ({
  type: "ALERT_SET",
  alert,
});

export const generalGetChannel = (channel) => ({
  type: "CHANNEL_GET",
  channel,
});

export const generalSetChannel = (channel) => ({
  type: "CHANNEL_SET",
  channel,
});
