import { put, call, take, all } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { Howl } from "howler";
import * as _ from "lodash";
import * as actions from "../actions";
import http from "../../utils/http";
import store from "..";
import socket from "../../utils/socket";
import audioNotif from "../../assets/audio/notif.mp3";
import helper from "../../utils/helper";

export function* interactionWatcher() {
  try {
    const data = yield call(http.get, "/v1/interaction");
    const dataFiltered = _.orderBy(
      data?.data?.serve.data,
      ["end_interaction", "created_at"],
      ["asc", "asc"]
    );
    yield put(
      actions.setInteraction({
        data: dataFiltered,
        count: data?.data?.serve.count,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* messageWatcher(action) {
  try {
    const state = store.getState();
    let result = [];
    let resultLoadMore = false;
    if (state.message.length === 0) {
      const { data } = yield call(http.post, "/v1/message", action.message);
      if (data?.serve.last_page <= parseInt(action.message.page)) {
        resultLoadMore = true;
      }
      result = data?.serve.data;
    } else {
      const validateMessage = state.message.filter(
        (v) => v.interaction_id === action.message.interaction_id
      );
      if (validateMessage.length === 0) {
        const { data } = yield call(http.post, "/v1/message", action.message);
        result = state.message.concat(data?.serve.data);
        if (data?.serve.last_page <= parseInt(action.message.page)) {
          resultLoadMore = true;
        }
      } else {
        if (action.message.page) {
          const { data } = yield call(http.post, "/v1/message", action.message);
          result = state.message.concat(data?.serve.data);
          if (data?.serve.last_page <= parseInt(action.message.page)) {
            resultLoadMore = true;
          }
        } else {
          result = state.message;
        }
      }
    }
    if (result.length > 0) {
      result = _.orderBy(result, ["created_at", "asc"]);
    }
    result = _.uniqBy(result, "id");
    yield all([
      put(actions.setMessage(result)),
      put(actions.generalSetLoadMore(resultLoadMore)),
    ]);
  } catch (err) {
    console.log(err);
  }
}
// eslint-disable-next-line
function* interactionEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      const state = store.getState();
      let result = {};
      const dirtyDataInteraction = state.interaction;
      if (data.last_message.action === "IN") {
        const dirtyNotification = state.notification;
        dirtyNotification.push(data);
        emit(actions.setNotification(dirtyNotification));
        var sound = new Howl({
          src: [audioNotif],
          autoplay: true,
          loop: false,
        });
        sound.play();
        helper.pushNotification(`${data.last_message.message}`);
      }
      const dirtyDataMessage = state.message;
      if (state.message.length === 0) {
        let resultMessage = _.orderBy(
          [data.last_message],
          ["created_at", "asc"]
        );
        emit(actions.setMessage(resultMessage));
      } else {
        const validateMessage = state.message.filter(
          (v) => v.id === data.last_message.id
        );
        if (validateMessage.length > 0) {
          const findIndexMessage = state.message.findIndex(
            (v) => v.id === data.last_message.id
          );
          if (findIndexMessage > -1) {
            dirtyDataMessage[findIndexMessage] = data.last_message;
            let resultMessage = _.orderBy(dirtyDataMessage, [
              "created_at",
              "asc",
            ]);
            emit(actions.setMessage(resultMessage));
          }
        } else {
          dirtyDataMessage.push(data.last_message);
          let resultMessage = _.orderBy(dirtyDataMessage, [
            "created_at",
            "asc",
          ]);
          emit(actions.setMessage(resultMessage));
        }
      }

      if (state.interaction.data.length === 0) {
        dirtyDataInteraction.data = [data];
        dirtyDataInteraction.count.queue = 1;
        result = dirtyDataInteraction;
      } else {
        const validateInteraction = state.interaction.data.filter(
          (v) => v.id === data.id
        );
        if (validateInteraction.length > 0) {
          const findIndexInteraction = state.interaction.data.findIndex(
            (v) => v.id === data.id
          );
          if (findIndexInteraction > -1) {
            dirtyDataInteraction.data[findIndexInteraction] = data;
            dirtyDataInteraction.count.queue =
              dirtyDataInteraction.count.queue + 1;
            result = dirtyDataInteraction;
          } else {
            result = state.interaction;
          }
        } else {
          dirtyDataInteraction.data.push(data);
          dirtyDataInteraction.count.queue =
            dirtyDataInteraction.count.queue + 1;
          result = dirtyDataInteraction;
        }
      }

      const dataFiltered = _.orderBy(
        result.data,
        ["end_interaction", "created_at"],
        ["asc", "asc"]
      );

      emit(actions.setInteraction({ data: dataFiltered, count: result.count }));
    };
    socket.interaction(socketEvent);
    return () => {};
  });
}

export function* interactionEventChannelWatcher() {
  try {
    const interaction = yield call(interactionEventChannel);
    while (true) {
      const action = yield take(interaction);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}
// eslint-disable-next-line
function* messageEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      const state = store.getState();
      let result = [];
      const dirtyDataMessage = state.message;
      if (data.action === "IN") {
        const dirtyNotification = state.notification;
        dirtyNotification.push(data);
        emit(actions.setNotification(dirtyNotification));
        var sound = new Howl({
          src: [audioNotif],
          autoplay: true,
          loop: false,
        });
        sound.play();
        helper.pushNotification(`${data.message}`);
      }
      const dirtyDataInteraction = state.interaction.data;
      if (dirtyDataInteraction.length > 0) {
        const validateInteraction = dirtyDataInteraction.filter(
          (v) => v.id === data.interaction_id
        );
        if (validateInteraction.length > 0) {
          const findIndexInteraction = dirtyDataInteraction.findIndex(
            (v) => v.id === data.interaction_id
          );
          if (findIndexInteraction > -1) {
            dirtyDataInteraction[findIndexInteraction].last_message = data;
            const dataFiltered = _.orderBy(
              dirtyDataInteraction,
              ["end_interaction", "created_at"],
              ["asc", "asc"]
            );
            emit(
              actions.setInteraction({
                data: dataFiltered,
                count: state.interaction.count,
              })
            );
          }
        }
      }
      if (state.message.length === 0) {
        result = [data];
      } else {
        const validateMessage = state.message.filter((v) => v.id === data.id);
        if (validateMessage.length > 0) {
          const findIndexMessage = state.message.findIndex(
            (v) => v.id === data.id
          );
          if (findIndexMessage > -1) {
            dirtyDataMessage[findIndexMessage] = data;
            result = dirtyDataMessage;
          } else {
            result = state.message;
          }
        } else {
          dirtyDataMessage.push(data);
          result = dirtyDataMessage;
        }
      }
      if (result.length > 0) {
        result = _.orderBy(result, ["created_at", "asc"]);
      }
      result = _.uniqBy(result, "id");
      emit(actions.setMessage(result));
      emit(actions.generalSetBottom(true));
    };
    socket.interactionMessage(socketEvent);
    return () => {};
  });
}

export function* messageEventChannelWatcher() {
  try {
    const message = yield call(messageEventChannel);
    while (true) {
      const action = yield take(message);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* loadingEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.generalSetLoading(data));
    };
    socket.loading(socketEvent);
    return () => {};
  });
}

export function* loadingEventChannelWatcher() {
  try {
    const loading = yield call(loadingEventChannel);
    while (true) {
      const action = yield take(loading);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* alertEvent() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.generalSetAlert(data));
    };
    socket.message(socketEvent);
    return () => {};
  });
}

export function* alertEventWatcher() {
  try {
    const message = yield call(alertEvent);
    while (true) {
      const action = yield take(message);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* qrEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.setQrWhatsapp(data));
    };
    socket.qrwhatsapp(socketEvent);
    return () => {};
  });
}

export function* qrEventChannelWatcher() {
  try {
    const qr = yield call(qrEventChannel);
    while (true) {
      const action = yield take(qr);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line
function* readyEventChannel() {
  return new eventChannel((emit) => {
    const socketEvent = (data) => {
      emit(actions.setQrWhatsapp(data));
    };
    socket.readywhatsapp(socketEvent);
    return () => {};
  });
}

export function* readyEventChannelWatcher() {
  try {
    const ready = yield call(readyEventChannel);
    while (true) {
      const action = yield take(ready);
      yield put(action);
    }
  } catch (error) {
    console.log(error);
  }
}
