export const getInteraction = (interaction) => ({
  type: "INTERACTION_GET",
  interaction,
});

export const setInteraction = (interaction) => ({
  type: "INTERACTION_SET",
  interaction,
});
export const getMessage = (message) => ({
  type: "MESSAGE_GET",
  message,
});

export const setMessage = (message) => ({
  type: "MESSAGE_SET",
  message,
});
export const getNotification = (notification) => ({
  type: "NOTIFCATION_GET",
  notification,
});

export const setNotification = (notification) => ({
  type: "NOTIFICATION_SET",
  notification,
});

export const getCurrent = (current) => ({
  type: "CURRENT_GET",
  current,
});

export const setCurrent = (current) => ({
  type: "CURRENT_SET",
  current,
});

export const setQrWhatsapp = (qr) => ({
  type: "QR_SET",
  qr,
});
