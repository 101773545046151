export const customerReducer = (state = [], action) => {
  switch (action.type) {
    case "CUSTOMER_GET":
      return state;
    case "CUSTOMER_SET":
      return action.customer;
    default:
      return state;
  }
};

export const customerCurrentReducer = (state = false, action) => {
  switch (action.type) {
    case "CUSTOMER_CURRENT_GET":
      return state;
    case "CUSTOMER_CURRENT_SET":
      return action.current;
    default:
      return state;
  }
};
