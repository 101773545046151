import { put, call, all } from "redux-saga/effects";
import * as actions from "../actions";
import http from "../../utils/http";
import store from "..";

export function* customerWatcher(param) {
  try {
    let result = [];
    let resultLoadMore = false;
    const state = store.getState();
    const { data } = yield call(
      http.get,
      `/v1/customer?name=${param.customer?.name}&page=${param.customer?.page}&limit=${param.customer?.limit}&except=${param.customer?.except}`
    );
    if (state.customer.length > 0) {
      if (data?.serve.data?.length > 0) {
        if (param.customer.nextPage > 0) {
          result = state.customer.concat(data?.serve.data);
        } else {
          result = data?.serve.data;
        }
      } else {
        result = [];
      }
    } else {
      result = data?.serve.data;
    }

    if (data?.serve?.current_page === data?.serve?.last_page) {
      resultLoadMore = false;
    } else {
      resultLoadMore = true;
    }
    yield all([
      put(actions.customerSet(result)),
      put(actions.generalSetLoadMore(resultLoadMore)),
      put(actions.generalSetLoading(false)),
    ]);
  } catch (err) {
    console.log(err);
    yield put(actions.generalSetLoading(false));
  }
}
