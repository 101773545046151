import { combineReducers } from "redux";
import * as general from "./general";
import * as interaction from "./interaction";
import * as customer from "./customer";
const appReducer = combineReducers({
  auth: general.generalAuthReducer,
  authRequest: general.generalAuthRequestReducer,
  interaction: interaction.interactionReducer,
  message: interaction.messageReducer,
  notification: interaction.notificationReducer,
  current: interaction.currentReducer,
  bottom: general.generalBottomReducer,
  more: general.generalLoadMoreReducer,
  draft: general.generalDraftReducer,
  loading: general.generalLoadingReducer,
  channel: general.generalChannelReducer,
  qr: interaction.qrReduer,
  alert: general.generalAlertReducer,
  customer: customer.customerReducer,
  customerCurrent: customer.customerCurrentReducer,
});

export default appReducer;
